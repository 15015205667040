import React from "react";
import Layout from "../components/Layout";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner" style={{backgroundImage: `url(../images/seguros.jpeg)`}}>
                        <h1>Seguros</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Te aseguramos, te protegemos y te asesoramos. Ofrecemos soluciones a la medida garantizadas
                            por la experiencia y por la metodología propia: Explora, Diseña, Construye, Ejecuta y
                            Mejora.
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>Nuestras marcas:</h1>
                            <section className="tiles">
                                <article style={{border: '3px solid #072146'}}>
                                    <div style={{padding: 10}}>
                                        <h1 style={{fontSize: 20, color: '#000000'}}>Empresas</h1>
                                        <p style={{color: '#000000'}}>Daños, SGMM, Transportes, Etc.</p>
                                    </div>
                                </article>
                                <article style={{border: '3px solid #072146'}}>
                                    <div style={{padding: 10}}>
                                        <h1 style={{fontSize: 20, color: '#000000'}}>Masivos</h1>
                                        <p style={{color: '#000000'}}>AP, Funerario, Gastos Médicos</p>
                                    </div>
                                </article>
                                <article style={{border: '3px solid #072146'}}>
                                    <div style={{padding: 10}}>
                                        <h1 style={{fontSize: 20, color: '#000000'}}>Personas</h1>
                                        <p style={{color: '#000000'}}>Autos, Vida, SGMM, Etc.</p>
                                    </div>
                                </article>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
